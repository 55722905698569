import { useStaticQuery, graphql } from 'gatsby'

const useCustomerSupportMetadata = () => {
  const query = useStaticQuery(graphql`
    query CustomerSupportMetadata {
      bannerBG: file(relativePath: { eq: "customer-support/banner-bg.png" }) {
        id
        ...CustomerSupportImageOptimize
      }

      ctaCardsRightImg: file(
        relativePath: { eq: "customer-support/card-img.jpg" }
      ) {
        id
        ...CustomerSupportImageOptimize
      }
      ctaCardsLeftImg: file(
        relativePath: { eq: "free-consultation/contact/right-img.jpg" }
      ) {
        id
        ...CustomerSupportImageOptimize
      }
    }

    fragment CustomerSupportImageOptimize on File {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          formats: [AUTO, WEBP]
        )
      }
    }
  `)

  return query
}

export default useCustomerSupportMetadata
