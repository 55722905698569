import React from 'react'

import LayoutInnerPage from '@src/layouts/InnerPage'
import Seo from '@components/seo/Index'
import ConsultationBanner from '@components/global/banner/ConsultationBanner'
import CtaCards from '@components/global/cards/CtaCards'
import ReachUsCards from '@components/global/cards/ReachUsCards'

import {
  pageData as page,
  bannerData,
  reachUsData,
  ctaCardsData,
} from '@src/data/CustomerSupport'
import useCustomerSupportMetadata from '@hooks/customer-support-metadata'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './CustomerSupport.module.scss'

const CustomerSupport = () => {
  const {
    bannerBG,
    ctaCardsLeftImg,
    ctaCardsRightImg,
  } = useCustomerSupportMetadata()
  const ctaCardsImg = [ctaCardsLeftImg, ctaCardsRightImg]

  return (
    <LayoutInnerPage hasBlackTransparentNav wrapperClassName={cx.wrapper}>
      <Seo title={page.title} uri={page.uri} />
      <ConsultationBanner
        hasDarkText
        hasSupportFormData
        wrapperClassName={cx.banner}
        breadcrumbs={page.breadcrumbs}
        bg={extractImage(bannerBG)}
        heading={bannerData.heading.text}
        subheading1={bannerData.subheading1.text}
        subheading2={bannerData.subheading2.text}
        subheading3={bannerData.stressSubheading.text}
        formHeading={bannerData.form.heading}
        formButtonText={bannerData.form.buttonText}
      />
      <ReachUsCards wrapperClassName={cx.reachUs} items={reachUsData.items} />
      <CtaCards
        wrapperClassName={cx.ctaCards}
        items={ctaCardsData}
        imgItems={ctaCardsImg}
      />
    </LayoutInnerPage>
  )
}

export default CustomerSupport
