import React from 'react'

export const pageData = {
  title: 'Customer Support',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    { text: 'Resources', url: '/resources/' },
    { text: 'Customer Support', url: '/resources/customer-support/' },
  ],
  uri: '/resources/customer-support/',
}

export const bannerData = {
  heading: {
    text: 'Hello, How Can We Help?',
    className: 'heading-1',
  },
  subheading1: {
    text: 'Agent Image Customer Support',
    className: 'subtitle-7',
  },
  subheading2: {
    text: 'Our Web Consultants are here at your service.',
    className: 'subtitle-3',
  },
  stressSubheading: {
    text:
      "If you're an existing customer and need help with your website or digital marketing projects, tell us more so we can assist you.",
    className: 'subtitle-5',
  },
  form: {
    heading: 'Send A Support Ticket',
    buttonText: 'Submit Ticket',
  },
}

export const reachUsData = {
  items: [
    {
      title: 'Want To Speak To A Web Consultant?',
      subtitle: 'Call Our Support Hotline',
      contact: '877.317.4111',
      serviceHours: '6AM - 6PM Pacific, Mon-Fri',
    },
    {
      title: 'Want To Email Support Directly?',
      subtitle: 'Send Us An Email At',
      contact: 'support@agentimage.com',
    },
    {
      title: 'Just Looking To Inquire?',
      subtitle: 'Give Us A Call',
      contact: '800.979.5799',
    },
  ],
}

export const ctaCardsData = [
  {
    title: 'Frequently Asked Questions',
    subtitle: 'Got questions?<br/> We’ve got answers!',
    cta: 'READ OUR FAQ',
    imgAlt: 'FAQs and meeting',
  },
  {
    title: 'Score More Marketing Tips',
    subtitle:
      "Explore the Agent Image blog for website tips and marketing advice. It's free!",
    cta: 'Visit our blog',
    imgAlt: 'Score More Marketing Tips',
  },
]
